import {Injectable} from '@angular/core';
import {MainService} from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class FriendshipService extends MainService {
  public listFriendships(params?) {
    let url = this.apiUrl + 'admin/friendship';

    if (params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.get(url);
  }

  public acceptFriendships(params?) {
    let url = this.apiUrl + 'admin/friendship/accept';

    if (params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.post(url, {});
  }

  public rejectFriendships(params?) {
    let url = this.apiUrl + 'admin/friendship/reject';

    if (params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.post(url, {});
  }
}
