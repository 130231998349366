import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Başlangıç',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Mesajlaşma',
    icon: 'message-square',
    link: '/messaging',
  },
  {
    label: 'Kullanıcılar',
    icon: 'users',
    link: '/user',
  },
  {
    label: 'Beğeniler',
    icon: 'thumbs-up',
    link: '/like',
  },
  {
    label: 'Arkadaşlık İstekleri',
    icon: 'user-plus',
    link: '/friendship',
  },
  {
    label: 'Hediyeler',
    icon: 'gift',
    link: '/gift',
  }
];
