import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private subject = new Subject<any>();

  constructor() {
  }

  sendMessage(message: any): void {
    this.subject.next(message);
  }

  clearMessage(): void {
    this.subject.next("");
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
