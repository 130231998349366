import {Injectable} from '@angular/core';
import {MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingPanelService extends MainService {

  public listMessages(conversationUserId = false, currentUserId = false) {
    let url = this.apiUrl + 'messaging-panel';
    if (conversationUserId && currentUserId) {
      url += '?conversationUserId=' + conversationUserId + '&currentUserId=' + currentUserId;
    }
    return this.httpService.get(url);
  }

  public send(sender, receiver, message, callback) {
    this.httpService.post(this.apiUrl + 'messaging-panel/send-message', {
      message,
      receiver,
      sender
    }).subscribe((response: any) => {
      callback(response);
    })
  }
}
