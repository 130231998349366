import { Injectable } from '@angular/core';
import {MainService} from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class LikeService extends MainService {
  listLikes(params?) {
    let url = this.apiUrl + 'admin/like';

    if(params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.get(url);
  }
}
