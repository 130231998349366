import { Component, OnInit } from '@angular/core';
import {FriendshipService} from '../../../services/admin/friendship.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../../../services/utility.service';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {GiftService} from '../../../services/admin/gift.service';
import {MainService} from '../../../services/main.service';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})
export class GiftComponent implements OnInit {
  rows = [];
  loadingIndicator = true;
  ColumnMode = ColumnMode;
  sentRequests = true;
  page: any = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  constructor(
    private giftService: GiftService,
    private router: Router,
    private route: ActivatedRoute,
    public utility: UtilityService
  ) {
    this.route.queryParams.subscribe(queryParams => {
      this.page.pageNumber = queryParams.page;
      this.getData({offset: queryParams.page});
    });
  }

  ngOnInit(): void {
  }

  getData(pageInfo = null, opts?) {
    const params: any = {
      sentRequests: this.sentRequests,
    };

    this.giftService.listGifts(params).subscribe((response: any) => {
      this.rows = response;
      this.loadingIndicator = false;
    });
  }

  changeData(value) {
    console.log(value)
  }

  setPage(pageInfo, opts?) {
    this.router.navigate([], {
      queryParams: {
        page: pageInfo.offset + 1
      },
      queryParamsHandling: 'merge',
    });
  }

  protected readonly MainService = MainService;
}
