import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {MainService} from './main.service';

class Deferred<T> {

  promise: Promise<T>;
  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      // @ts-ignore
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

@Injectable({providedIn: 'root'})
export class UploadService extends MainService {
  /**
   * Upload
   */
  uploadPhoto(file: any, type: any) {
    const deferred = new Deferred<any>();

    const req = new HttpRequest('POST', `${this.apiUrl}media/upload-photo`, {
      file,
      ...type
    }, {
      reportProgress: true
    });

    this.httpService.request(req).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          this.eventService.sendMessage({
            channel: 'upload:progress',
            status: 'pending',
            message: 'Sunucuya veriler gönderiliyor.'
          });
          break;
        case HttpEventType.ResponseHeader:
          this.eventService.sendMessage({
            channel: 'upload:progress',
            status: 'pending',
            message: 'Sunucudan cevap bekleniyor.'
          });
          break;
        case HttpEventType.User:
          break;
        case HttpEventType.UploadProgress:
          const ukbLoaded = Math.round(event.loaded / 1024);
          this.eventService.sendMessage({
            channel: 'upload:progress',
            status: 'pending',
            progressBar: 100 * (event.loaded || 1) / (event.total || 1),
            message: 'Sunucuya gönderilen veri boyutu: ' + ukbLoaded + 'Kb'
          });
          break;
        case HttpEventType.DownloadProgress:
          const kbLoaded = Math.round((event.loaded || 1) / 1024);
          this.eventService.sendMessage({
            channel: 'upload:progress',
            status: 'pending',
            message: 'Sunucudan cevap alınıyor.'
          });
          this.eventService.sendMessage({
            channel: 'upload:progress',
            status: 'pending',
            message: 'Sunucudan yanıt bekleniyor...'
          });
          break;
        case HttpEventType.Response:
          if (event.ok) {
            deferred.resolve(event.body);
            this.eventService.sendMessage({
              channel: 'upload:progress',
              status: true,
              message: 'Yükleme tamamlandı.'
            });
          } else {
            this.eventService.sendMessage({
              channel: 'upload:progress',
              status: false,
              message: 'İşlem gerçekleştirilirken hata oluştu!.'
            });
          }
      }
    });
    return deferred.promise;
  }
}
