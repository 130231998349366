import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import {ChatComponent} from './views/pages/chat/chat.component';
import {UserComponent} from './views/pages/user/user.component';
import {FriendshipRequestsComponent} from './views/pages/friendship-requests/friendship-requests.component';
import {LikeComponent} from './views/pages/like/like.component';
import {GiftComponent} from './views/pages/gift/gift.component';
import {AddGiftComponent} from './views/pages/gift/add-gift/add-gift.component';
import {EditUserComponent} from './views/pages/user/edit-user/edit-user.component';
import {UserPhotoComponent} from './views/pages/user/user-photo/user-photo.component';
import {AddUserComponent} from './views/pages/user/add-user/add-user.component';


const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      {
        path: 'messaging',
        component: ChatComponent
      },
      {
        path: 'user',
        component: UserComponent
      },
      {
        path: 'user/add',
        component: AddUserComponent
      },
      {
        path: 'user/edit/:id',
        component: EditUserComponent
      },
      {
        path: 'user/:id/photo',
        component: UserPhotoComponent
      },
      {
        path: 'friendship',
        component: FriendshipRequestsComponent
      },
      {
        path: 'like',
        component: LikeComponent
      },
      {
        path: 'gift',
        component: GiftComponent
      },
      {
        path: 'gift/add',
        component: AddGiftComponent
      },
      {
        path: 'gift/edit/:id',
        component: AddGiftComponent
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
