import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CroppieOptions} from 'croppie';
import {EventService} from 'src/app/services/event.service';
import {UploadService} from '../../services/upload.service';
import * as Croppie from 'croppie';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss']
})
export class PhotoUploadComponent implements OnInit, AfterViewInit {
  @Input() data: any = {};
  item: any = {};
  uploadBox: any;
  progressValue: any = null;
  message: any;
  progressColor: any = 'bg-info';
  croppieOptions: CroppieOptions;
  @ViewChild('croppie') public croppieDirective: any;

  constructor(
    private _service: UploadService,
    private eRef: ElementRef,
    private eventService: EventService,
    public activeModal: NgbActiveModal
  ) {
    this.eventService.getMessage().subscribe((data) => setTimeout(() => {
      if (data.channel === 'upload:progress') {
        if (data.progressBar) {
          this.progressValue = data.progressBar;
        }
        this.message = data.message;
        if (data.status === true) {
          this.progressColor = 'bg-success';
        } else if (data.status === false) {
          this.progressColor = 'bg-danger';
        }
      }
    }, 0));
  }

  ngOnInit() {
    const dimensions = this.eRef.nativeElement.getElementsByClassName('modal-body')[0];
    const ratio = this.data.sizes.height / this.data.sizes.width;

    this.croppieOptions = {
      viewport: {
        width: dimensions.clientWidth - 20,
        height: (dimensions.clientWidth - 20) * ratio
      },
      boundary: {
        width: dimensions.clientWidth - 20,
        height: (dimensions.clientWidth - 20) * ratio
      },
      mouseWheelZoom: false,
      enableExif: true,
      enableResize: false
    };

    if(dimensions.clientWidth > this.data.sizes.width) {
      this.croppieOptions.viewport.width = this.data.sizes.width;
      this.croppieOptions.viewport.height = this.data.sizes.height;
      this.croppieOptions.boundary.width = this.data.sizes.width;
      this.croppieOptions.boundary.height = this.data.sizes.height;
    }
  }

  // Fotoğraf Yükleme
  public doCrop(event): void {
    const reader = new FileReader();
    const croppievr = this.croppieDirective;

    reader.onload = (e: any) => {
      croppievr.bind({
        url: e.target.result,
        orientation: 4
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  public doUpload() {
    this.croppieDirective.result({
      type: 'canvas',
      size: {
        width: this.data.sizes.width,
        height: this.data.sizes.height
      },
      circle: false
    }).then(image => {
      this._service.uploadPhoto(image, this.data).then((data: any) => {
        this.activeModal.close(data);
      });
    });
  }

  handleUpdate(event) {

  }

  close() {
    this.activeModal.dismiss();
  }

  public save(item) {
    this.activeModal.close(item);
  }

  public ngAfterViewInit() {
    this.croppieDirective = new Croppie(document.getElementById('croppie'), this.croppieOptions);
    console.log(this.croppieDirective)
    /*this.croppieDirective.croppie.bind({
      url: 'assets/img/no_photo_post.png'
    });*/
  }
}
