import { Injectable } from '@angular/core';
import {Socket} from "ngx-socket-io";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(
    private socket: Socket
  ) { }

  // emit event
  chat(data:any) {
    this.socket.emit('senbenchat', data);
  }

  // listen event
  onChat() {
    return this.socket.fromEvent('senbenchat');
  }
}
