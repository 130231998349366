import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MessagingPanelService} from '../../../services/messaging-panel.service';
import {UtilityService} from '../../../services/utility.service';
import {SocketService} from '../../../services/socket.service';
import {Socket} from 'ngx-socket-io';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit {
  users: any = [];
  socketSub: any;
  selectedChat: any = {};
  chatOpen = false;
  messages: any = [];
  messageContent: any = '';
  @ViewChild('messageContainer', {static: false}) messageContainer: ElementRef;

  defaultNavActiveId = 1;

  constructor(
    private messageService: MessagingPanelService,
    private socketService: SocketService,
    private socket: Socket,
    public utility: UtilityService,
    private cd: ChangeDetectorRef
  ) {
    this.socketSub = this.socketService.onChat().subscribe((res: any) => {
      if (res.type === 'message') {
        // console.log('Şimdiki kullanıcı mesaj yazdı.');
        setTimeout(() => {
          this.getMessageList();
          // this.getSoldPacks();
        }, 2000)
      }
    });
  }

  ngOnInit(): void {
    this.getMessageList();
  }

  ngAfterViewInit(): void {

    // Show chat-content when clicking on chat-item for tablet and mobile devices
    document.querySelectorAll('.chat-list .chat-item').forEach(item => {
      item.addEventListener('click', event => {
        document.querySelector('.chat-content').classList.toggle('show');
      })
    });

  }

  getMessageList() {
    this.messageService.listMessages().subscribe((res: any) => {
      this.users = res;
      this.users.sort((a, b) => {
        return b.status - a.status;
      });
      this.cd.detectChanges();
    })
  }

  getMessages(receiver, sender) {
    this.messageService.listMessages(receiver, sender).subscribe((res: any) => {
      this.messages = res;
      this.cd.detectChanges();
      setTimeout(() => {
        this.scrollToBottom();
      }, 500)
    });
  }

  setCurrentChat(receiver, sender) {
    this.selectedChat.receiver = receiver;
    this.selectedChat.sender = sender;
    this.chatOpen = true;
    this.getMessages(receiver.id, sender.id);
  }

  scrollToBottom = () => {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  setTypingStatus(status) {
    this.selectedChat.sender.typing = status;

    this.broadcastTypeSocket('typing', this.selectedChat.receiver.id, this.selectedChat.sender.id, status)
  }

  broadcastTypeSocket(type, receiverId, senderId, status) {
    this.socket.emit('senbenchat', {
      type,
      receiver: this.selectedChat.receiver.id,
      sender: this.selectedChat.sender.id,
      status
    });
  }

  listenTypeSocket() {
    this.socket.on('senbenchat', (response: any) => {
      console.log(response)
      switch (response.type) {
        case 'typing':
          if (this.selectedChat.sender.id === response.receiver) {
            this.selectedChat.receiver = response.status;
          } else if (this.selectedChat.sender.id === response.sender) {
            this.selectedChat.sender.typing = response.status;
          }
          break;
        case 'message':
          this.getMessages(this.selectedChat.receiver.id, this.selectedChat.sender.id);
          break;
      }
    })
  }

  // back to chat-list for tablet and mobile devices
  backToChatList() {
    this.chatOpen = false;
  }

  sendMessage() {
    console.log(this.messageContent)
    this.broadcastTypeSocket('typing', this.selectedChat.receiver.id, this.selectedChat.sender.id, false)
    this.messageService.send(this.selectedChat.sender.id, this.selectedChat.receiver.id, this.messageContent, (res) => {
      this.broadcastTypeSocket('message', this.selectedChat.receiver.id, this.selectedChat.sender.id, true)
      this.getMessages(this.selectedChat.receiver.id, this.selectedChat.sender.id);
      this.messageContent = '';
    });
  }
}
