import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public currentUser: any = {};
  public googleCredentials = {
    webClientId: '229305795447-47lhppp6ctc3vgj541etcvpjpj4s8nr1.apps.googleusercontent.com'
  }

  constructor(
    private location: Location
  ) {
  }

  public goBack() {
    this.location.back();
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  public getUserStatus(user) {
    switch (user.status) {
      case 0:
        return 'NEW';
      case 1:
        return 'VIP'
      case 2:
        return 'DELETED';
      case 3:
        return 'SUSPENDED';
      default:
        return false;
    }
  }

  public isVIP(user) {
    return this.getUserStatus(user) === 'VIP';
  }

  public isNull(variable) {
    return variable === '' || variable === null;
  }

  public getUserGender(user) {
    switch (user.gender) {
      case 0:
        return '-';
      case 1:
        return 'Erkek'
      case 2:
      default:
        return 'Kadın';
    }
  }

  public getAvatar(user: any, width = 150) {
    if (user.avatar.includes('media/image')) {
      return MainService.url + user.avatar + '?w=' + width;
    }
    if (user.avatar !== 'avatar.png' && !user.avatar.includes('google')) {
      return 'https://cumbusapp.com/cmbs/uploads/profile/' + user.avatar;
    }
    return user.avatar;
  }

  public getPhoto(photo: any, width = 150) {
    return MainService.url + photo + '?w=' + width;
  }
}
