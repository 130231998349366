import {NgModule} from '@angular/core';
import {PhotoUploadComponent} from './photo-upload.component';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [
    PhotoUploadComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PhotoUploadComponent
  ]
})
export class PhotoUploadModule {
}
