import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventService} from './event.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public static url = 'https://api.cumbusapp.com';
  public apiUrl = 'https://api.cumbusapp.com/api/';
  // public static url = 'https://senben-api.test';
  // public apiUrl = 'https://senben-api.test/api/';

  constructor(
    public httpService: HttpClient,
    protected eventService: EventService
  ) {
  }
}
