import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {MainService} from '../../../../services/main.service';
import {UserService} from '../../../../services/admin';
import {PhotoUploadComponent} from '../../../photo-upload/photo-upload.component';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @ViewChild('photoPreview') photoPreview: ElementRef;
  user: any = {
    gender: 2
  };

  constructor(
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private route: Router,
    private mainService: MainService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
  }

  isValidHttpUrl(string: any) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  setPreview(url) {
    if (this.isValidHttpUrl(url)) {
      this.photoPreview.nativeElement.src = url;
    } else {
      this.photoPreview.nativeElement.src = this.mainService.apiUrl + '..' + url;
    }
  }

  addPhoto(sizes) {
    const modalRef = this.modalService.open(PhotoUploadComponent, {
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.data = {
      type: 'avatar',
      userId: this.user.id,
      title: 'Profil Fotosu',
      sizes
    };

    modalRef.result.then((data) => {
      // this.getUser()
    });
  }

  save() {
    this.user.type = 8;
    this.userService.addUser(this.user).subscribe((response: any) => {
      if (response.status) {
        alert('Eklendi!');
        this.route.navigate(['/user'])
      } else {
        alert('Hata!')
      }
    })
  }
}
