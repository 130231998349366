import {Injectable} from '@angular/core';
import {MainService} from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class UserPhotoService extends MainService {

  list(userId) {
    return this.httpService.get(this.apiUrl + 'admin/user-photo/' + userId);
  }

  add(userId, img) {
    return this.httpService.post(this.apiUrl + 'admin/user-photo/' + userId, {img});
  }

  delete(userId, imgId) {
    return this.httpService.delete(this.apiUrl + 'admin/user-photo/' + imgId);
  }
}
