import {Injectable} from '@angular/core';
import {MainService} from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends MainService {
  listUsers(params?) {
    let url = this.apiUrl + 'admin/user';

    if (params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.get(url);
  }

  public getUser(id: any) {
    return this.httpService.get(this.apiUrl + 'admin/user/' + id);
  }

  public addUser(data) {
    return this.httpService.post(this.apiUrl + 'admin/user/', data);
  }

  public editUser(id, data) {
    return this.httpService.put(this.apiUrl + 'admin/user/' + id, data);
  }

  public deleteUser(user: any) {
    return this.httpService.delete(this.apiUrl + 'admin/user/' + user.id);
  }
}
