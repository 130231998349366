import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../../../services/utility.service';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {FriendshipService} from '../../../services/admin/friendship.service';

@Component({
  selector: 'app-friendship-requests',
  templateUrl: './friendship-requests.component.html',
  styleUrls: ['./friendship-requests.component.scss']
})
export class FriendshipRequestsComponent implements OnInit {
  rows = [];
  loadingIndicator = true;
  ColumnMode = ColumnMode;
  sentRequests = true;
  page: any = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  constructor(
    private friendshipService: FriendshipService,
    private router: Router,
    private route: ActivatedRoute,
    public utility: UtilityService
  ) {
    this.route.queryParams.subscribe(queryParams => {
      this.page.pageNumber = queryParams.page;
      this.getData({offset: queryParams.page});
    });
  }

  ngOnInit(): void {
  }

  getData(pageInfo = null, opts?) {
    if (pageInfo === null) {
      pageInfo = {
        offset: this.page.pageNumber
      };
    }

    const params: any = {
      sentRequests: this.sentRequests,
      ...pageInfo,
      page: Number(pageInfo.offset)
    };

    this.friendshipService.listFriendships(params).subscribe((response: any) => {
      this.rows = response.data;
      this.page = {
        size: Number(response.per_page),
        totalElements: response.total,
        totalPages: Math.ceil(response.total / Number(response.per_page)),
        pageNumber: response.current_page
      };
      this.loadingIndicator = false;
    });
  }

  changeData(value) {
    console.log(value)
  }

  setPage(pageInfo, opts?) {
    this.router.navigate([], {
      queryParams: {
        page: pageInfo.offset + 1
      },
      queryParamsHandling: 'merge',
    });
  }

  accept(request) {
    console.log('accepted');
    this.friendshipService.acceptFriendships({id: request.id}).subscribe((response: any) => {
      console.log(response)
      if(response.status) {
        this.getData({offset: 0});
      }
    })
  }

  reject(request) {
    console.log('rejected');
    this.friendshipService.rejectFriendships({id: request.id}).subscribe((response: any) => {
      console.log(response)
      if(response.status) {
        this.getData({offset: 0});
      }
    })
  }
}
