import {Injectable} from '@angular/core';
import {MainService} from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class GiftService extends MainService {

  public listGifts(params?) {
    let url = this.apiUrl + 'admin/gift';

    if (params) {
      url = url + '?' + new URLSearchParams(params).toString();
    }

    return this.httpService.get(url);
  }

  public addGift(data) {
    return this.httpService.post(this.apiUrl + 'admin/gift', data);
  }
}
