import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {LayoutModule} from './views/layout/layout.module';
import {AuthGuard} from './core/guard/auth.guard';

import {AppComponent} from './app.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';

import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {ChatComponent} from './views/pages/chat/chat.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HttpClientModule} from '@angular/common/http';
import {MomentModule} from 'ngx-moment';
import {SocketIoModule} from 'ngx-socket-io';
import {Utf8EmojisToImagesModule} from 'ng-utf8-emojis-to-images';
import {BypassSecurityPipe} from './core/bypass-security.pipe';
import {FormsModule} from '@angular/forms';
import {UserComponent} from './views/pages/user/user.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FeatherIconModule} from './core/feather-icon/feather-icon.module';
import {FriendshipRequestsComponent} from './views/pages/friendship-requests/friendship-requests.component';
import {LikeComponent} from './views/pages/like/like.component';
import {GiftComponent} from './views/pages/gift/gift.component';
import {AddGiftComponent} from './views/pages/gift/add-gift/add-gift.component';
import {PhotoUploadModule} from './views/photo-upload/photo-upload.module';
import { EditUserComponent } from './views/pages/user/edit-user/edit-user.component';
import { UserPhotoComponent } from './views/pages/user/user-photo/user-photo.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {MainService} from './services/main.service';
import { AddUserComponent } from './views/pages/user/add-user/add-user.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://api.cumbusapp.com/api/media/upload-photo',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ChatComponent,
    BypassSecurityPipe,
    UserComponent,
    FriendshipRequestsComponent,
    LikeComponent,
    GiftComponent,
    AddGiftComponent,
    EditUserComponent,
    UserPhotoComponent,
    AddUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    NgbModule,
    PerfectScrollbarModule,
    SocketIoModule.forRoot({
      url: 'https://aliosocket.cumbusapp.com', options: {}
    }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    Utf8EmojisToImagesModule,
    FormsModule,
    NgxDatatableModule,
    FeatherIconModule,
    PhotoUploadModule,
    DropzoneModule
  ],
  providers: [
    AuthGuard,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
