import {Component, OnInit} from '@angular/core';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {UserService} from '../../../services/admin';
import {UtilityService} from '../../../services/utility.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  rows = [];
  loadingIndicator = true;
  reorderable = false;
  ColumnMode = ColumnMode;
  showBotProfiles = false;
  showVIPOnlyProfiles = false;
  page: any = {
    size: 20,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public utility: UtilityService
  ) {
    this.route.queryParams.subscribe(queryParams => {
      this.page.pageNumber = queryParams.page;
      this.getData({offset: queryParams.page});
    });
  }

  ngOnInit(): void {
  }

  getData(pageInfo = null, opts?) {
    if (pageInfo === null) {
      pageInfo = {
        offset: this.page.pageNumber
      };
    }

    const params: any = {
      showBotProfiles: this.showBotProfiles,
      vip: this.showVIPOnlyProfiles,
      ...pageInfo,
      page: Number(pageInfo.offset)
    };

    this.userService.listUsers(params).subscribe((response: any) => {
      this.rows = response.data;
      this.page = {
        size: Number(response.per_page),
        totalElements: response.total,
        totalPages: Math.ceil(response.total / Number(response.per_page)),
        pageNumber: response.current_page
      };
      this.loadingIndicator = false;
    });
  }

  setPage(pageInfo, opts?) {
    this.router.navigate([], {
      queryParams: {
        page: pageInfo.offset + 1
      },
      queryParamsHandling: 'merge',
    });
  }

  deleteUser(user:any) {
    this.userService.deleteUser(user).subscribe((response:any) => {
      if (response.status) {
        alert('Silindi!');
        this.getData()
      } else {
        alert('Hata!')
      }
    })
  }
}
