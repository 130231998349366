import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserPhotoService} from '../../../../services/admin/user-photo.service';
import {DropzoneComponent, DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {MainService} from '../../../../services/main.service';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit {
  userId: any = '';
  photos: any = [];
  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: 5000,
    errorReset: 5000,
    cancelReset: 5000
  };
  @ViewChild(DropzoneComponent, {static: false}) componentRef?: DropzoneComponent;

  constructor(
    private route: ActivatedRoute,
    private userPhotoService: UserPhotoService
  ) {
    this.userId = this.route.snapshot.params.id;
    this.getPhotos();
  }

  ngOnInit(): void {
  }

  isValidHttpUrl(urlString: any) {
    let url;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  getPhotos() {
    this.userPhotoService.list(this.userId).subscribe((response: any) => {
      this.photos = response;
    })
  }

  showPhoto(photo) {
    if (this.isValidHttpUrl(photo)) {
      return photo;
    } else {
      return this.userPhotoService.apiUrl + '..' + photo;
    }
  }

  deletePhoto(imgId) {
    this.userPhotoService.delete(this.userId, imgId).subscribe((response: any) => {
      console.log(response);

      if (response.status) {
        this.getPhotos();
      }
    })
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    const img = JSON.parse(args[1]).link;

    this.userPhotoService.add(this.userId, img).subscribe((response: any) => {
      if (response.status) {
        this.componentRef.directiveRef.reset();
        this.getPhotos();
      }
    })
  }
}
