import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PhotoUploadComponent} from 'src/app/views/photo-upload/photo-upload.component';
import {MainService} from '../../../../services/main.service';
import {GiftService} from '../../../../services/admin/gift.service';

@Component({
  selector: 'app-add-gift',
  templateUrl: './add-gift.component.html',
  styleUrls: ['./add-gift.component.scss']
})
export class AddGiftComponent implements OnInit {
  @ViewChild('photoPreview') photoPreview: ElementRef;
  new: any = {};

  constructor(
    private modalService: NgbModal,
    private mainService: MainService,
    private giftService: GiftService
  ) {
  }

  ngOnInit(): void {
  }

  isValidHttpUrl(string: any) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  setPreview(url) {
    if (this.isValidHttpUrl(url)) {
      this.photoPreview.nativeElement.src = url;
    } else {
      this.photoPreview.nativeElement.src = this.mainService.apiUrl + '..' + url;
    }
    console.log(this.photoPreview.nativeElement.src)
  }

  addPhoto(sizes) {
    const modalRef = this.modalService.open(PhotoUploadComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      type: 'gift',
      title: 'Hediye',
      sizes
    };
    modalRef.result.then((data) => {
      this.setPreview(data);
      console.log(data)

      this.new.icon = data;
    });
  }

  save() {
    this.giftService.addGift(this.new).subscribe((response: any) => {
      console.log(response)
      alert('Eklendi')
    })
  }
}
